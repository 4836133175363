@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    @apply bg-purple-500 text-white;
}

html {
    @apply text-gray-700;
}

footer {
    @apply w-full pb-14 bg-purple-400 bg-gradient-to-tr from-purple-400 to-purple-600;
}

.hover-effect {
    @apply transition duration-500 ease-in-out transform;
}

.hover-effect:hover {
    @apply scale-105;
}

.header-container {
    @apply h-screen bg-bottom mx-auto flex p-4 items-center justify-around flex-col bg-gray-200;
}

.bg-repeating {
    background-image: url("../images/background.webp");
}

.title {
    @apply text-3xl font-extrabold tracking-tight leading-snug;
}

.purple-label {
    @apply my-3 text-xl font-bold text-white px-4 py-1 inline-block rounded-xl;
}

.down-circle {
    @apply rounded-full h-12 w-12 flex items-center justify-center cursor-pointer transition-opacity duration-500;
}

.down-circle-container {
    @apply absolute bottom-1;
}

.down-circle.opacity-0 {
    @apply pointer-events-none;
}

.purple-gradient {
    @apply bg-purple-400 bg-gradient-to-tr from-purple-400 to-purple-600;
}

.link-card {
    @apply max-w-sm rounded-xl overflow-hidden transition duration-500 ease-in-out transform cursor-pointer text-purple-800;
}

.link-card:hover {
    @apply scale-110 shadow-lg text-black bg-purple-400;
}

.link-card-icon {
    @apply p-2;
}

.link-card-icon > i {
    @apply text-4xl px-4;
}

.link-card-text {
    @apply py-2 font-bold text-lg text-center;
}

.code-block {
    @apply my-4 bg-gray-200 px-4 py-2 rounded-lg w-full;
}

@screen md {

    .link-card-icon {
        @apply px-8 pt-4 pb-2;
    }

    .link-card-icon > i {
        @apply text-6xl;
    }

    .link-card-text {
        @apply text-xl;
    }

    .purple-label {
        @apply text-2xl;
    }

    .title {
        @apply text-6xl;
    }

}

@screen lg {
    .header-container {
        @apply flex-row;
    }
}
